import React from 'react';
import { Link } from 'gatsby';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

import Styles from './footer.module.scss'

export default () => (

  <footer>
    <div className="container">
      <ul className={Styles.list}>
        <li className={Styles.advertiseLine}>
          <Link 
            to="/graphic-design-agency-london"
            className={Styles.advertiseLink}
          >
            Graphic Design
          </Link>
          <div className={Styles.underline}></div>
        </li>
        <li className={Styles.advertiseLine}>
          <Link 
            to="/mobile-app-development-london"
            className={Styles.advertiseLink}
          >
            Mobile App Development
          </Link>
          <div className={Styles.underline}></div>
        </li>
        <li className={Styles.advertiseLine}>
          <Link 
            to="/web-development-london"
            className={Styles.advertiseLink}
          >
            Web Development
          </Link>
          <div className={Styles.underline}></div>
        </li>
      </ul>
      <p className={Styles.copy}>
        Copyright &copy; Radium / Cookie &amp; Privacy Policy / Legal Notices
      </p>
      <ul className={Styles.socialList}>
        <li className={Styles.socialLine}>
          <a 
            href="https://www.facebook.com/radiumagency"
            target="_blank" 
            className={Styles.socialLink}
            rel="noopener noreferrer icon"
          >
            <FaFacebookSquare />
          </a>
        </li>
        <li className={Styles.socialLine}>
          <a 
            href="https://www.instagram.com/radium_agency/?hl=en"
            target="_blank" 
            className={Styles.socialLink}
            rel="noopener noreferrer icon"
          >
            <FaInstagram />
          </a>
        </li>
      </ul>
    </div>
  </footer>
);
