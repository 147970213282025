import React from 'react';
import { Helmet } from 'react-helmet';

import Header from '../header/header';
import GetInTouch from '../get-in-touch/get-in-touch';
import Footer from '../footer/footer';

export default ({ children }) => (
  
  <div>
    <Helmet>
      <link 
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,700" 
        rel="stylesheet"
      />
    </Helmet>
    <Header/>
      {children}
    <GetInTouch />
    <Footer />
  </div>
);