import React from 'react';

export default ({ backgroundImg, mainTitle, mainText }) => (
  
  <div>
    <section 
      className="inner-pages__background" 
      style={backgroundImg}>
    </section>
    <section className="container">
      <div className="inner-pages__main-title-container">
        <h1 className="inner-pages__main-title">
          {mainTitle}
        </h1>
        <p className="inner-pages__main-text">
          {mainText}
        </p>
      </div>
    </section>
  </div>
);